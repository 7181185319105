import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
@Injectable()
export class SharedService {
  // Observable string sources
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  private user_change = new Subject<any>();
  userChange$ = this.user_change.asObservable();
  userChange(change:any) {
    this.user_change.next(change)
  }

  private user_data_change = new Subject<any>();
  userDataChange$ = this.user_data_change.asObservable();
  userDataChange(change:any) {
    this.user_data_change.next(change)
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
}


