import {  Component, Input, OnInit } from '@angular/core';
import { ApiService } from "../../api.service";
import {ActivatedRoute} from "@angular/router";



@Component({
  selector: 'app-orphan-reviews',
  templateUrl: './orphan-reviews.component.html',
  styleUrls: ['./orphan-reviews.component.css']
})
export class OrphanReviewsComponent implements OnInit {


  @Input('pageOffset') pageOffset: number;
  @Input('pageIndex') pageIndex: number;


  length: number;
  orphan_reviews = [];
  public from: number;
  public to: number;
  public total: number = 0;
  public perPage: number = 25;
  public page: number = 1;
  public merchant_id: number;
  public last_page: number;



  constructor(private api: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.hasOwnProperty('page')) {
        this.page = Number(queryParams['page']);
      } else {
        this.page = 1;
      }

      this.api.get_orphan_reviews(this.page).subscribe(res => {
        this.orphan_reviews = res['data'];
        this.total = res['total'];
        this.from = res['from'];
        this.to = res['to'];
        this.last_page = res['last_page'];

      })
    })
  }

  submit(orphan){
    this.api.insert_into_reviews(orphan).subscribe(res=> {
        console.log(res);
    })
    this.api.get_orphan_reviews(this.page).subscribe(res =>{
      this.orphan_reviews = res['data'];
      this.total = res['total'];
      this.from = res['from'];
      this.to = res['to'];
      this.last_page = res['last_page'];
    })
    window.alert("Inserted successfully");
    orphan['new_id'] = '';
  }

}
