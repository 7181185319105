 // @ts-ignore
import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {ApiService} from '../../api.service';
import {AuthService} from '../../auth.service';
import {SharedService} from '../../shared-service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent implements OnInit {

  public current_user:boolean;
  public user:User;
  @Input() user_id;

  constructor(private api: ApiService, private auth: AuthService, private shared_service:SharedService) {
    shared_service.userChange$.subscribe(
      data => {
        this.user_id = data['user_id']
        this.get_header()
      });

    shared_service.userDataChange$.subscribe(data => {
      console.log(data)
      this.user = data['user']
    })
  }

  ngOnInit() {
    this.get_header()
  }

  get_header(){
    this.api.get_user_header(this.user_id).subscribe(res=>{
      this.user = res['user']
      this.current_user = this.auth.getAuthorizationToken() && this.auth.get_user().id == this.user.id;
    })
  }

}
