import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-merchant-basic-details',
  templateUrl: './merchant-basic-details.component.html',
  styleUrls: ['./merchant-basic-details.component.css']
})
export class MerchantBasicDetailsComponent implements OnInit {

  constructor(private api: ApiService, public auth: AuthService) { }
  public merchants;
  public merchant_id;
  public merchantdetails;
  public merchant_letter;
  public show_details:boolean=false;
  ngOnInit() {

    this.api.get_merchants().subscribe(res=>{
      this.merchants = res
      console.log(res);
    })

  }
  get(merchant_letter){
    this.api.get_merchant_details_byname(this.merchant_letter).subscribe(res=>{
      this.show_details = true;
      this.merchantdetails = res;
      console.log(res);
    })
  }


}
