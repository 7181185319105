import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {HttpClientModule} from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpErrorHandler} from './http-error-handler.service';
import {MessageService} from './message.service';
import { MemberBrowseComponent } from './list/member-browse/member-browse.component';
import { SideMenuComponent } from './list/side-menu/side-menu.component';
import {SingletonService} from './singleton';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MemberSearchComponent } from './list/member-search/member-search.component';
import { ListHeaderComponent } from './list/list-header/list-header.component';
import { TopStripComponent } from './top-strip/top-strip.component';
import { FooterComponent } from './footer/footer.component';
import { httpInterceptorProviders } from './http-interceptors';
import { MemberDetailsComponent } from './list/member-details/member-details.component';
import { PrivateMessageComponent } from './private-message/private-message.component';
import { EditMemberInfoComponent } from './list/edit-member-info/edit-member-info.component';
import { MemberApprovalComponent } from './list/member-approval/member-approval.component';
import { SubgroupsComponent } from './list/subgroups/subgroups.component';
import { AttendanceDashboardComponent } from './list/attendance-dashboard/attendance-dashboard.component';
import { AttendanceCategoriesComponent } from './list/attendance-categories/attendance-categories.component';
import { ListSettingsComponent } from './list/list-settings/list-settings.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ListPermissionsComponent } from './list/list-permissions/list-permissions.component';
import { AttendanceSearchComponent } from './list/attendance-search/attendance-search.component';
import { ListFieldsComponent } from './list/list-fields/list-fields.component';
import { SeeMessagesComponent } from './list/see-messages/see-messages.component';
import { DeliveryReportsComponent } from './delivery-reports/delivery-reports.component'
import { GoogleChartsModule } from 'angular-google-charts';
import { InboxComponent } from './inbox/inbox.component';
import { SentMailComponent } from './sent-mail/sent-mail.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MessageComponent } from './message/message.component';
import { ListComponent } from './list/list.component';
import { UserComponent } from './user/user.component';
import { UserHeaderComponent } from './user/user-header/user-header.component';
import { UserSidePanelComponent } from './user/user-side-panel/user-side-panel.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UrlComponent } from './url/url.component';
import {AuthGuardService} from './guards/auth-guard.service';
import { AgmCoreModule } from '@agm/core';
import { CategoryReviewsComponent } from './category-reviews/category-reviews.component';
import { RegisterComponent } from './register/register.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VerifyComponent } from './verify/verify.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import {SharedService} from './shared-service';
import { PeoplePinningComponent } from './people-pinning/people-pinning.component';
import { UserSideMenuComponent } from './user-side-menu/user-side-menu.component';
import { BasicDataComponent } from './user/basic-data/basic-data.component';
import { UpdatePasswordComponent } from './user/update-password/update-password.component';
import { SecretQuestionComponent } from './user/secret-question/secret-question.component';
import { UpdateMobileComponent } from './user/update-mobile/update-mobile.component';
import { UpdateEmailComponent } from './user/update-email/update-email.component';
import { OtherSettingsComponent } from './user/other-settings/other-settings.component';
import { EmailNotificationsComponent } from './user/email-notifications/email-notifications.component';
import { PrefillComponent } from './list/prefill/prefill.component';
import { InviteComponent } from './invite/invite.component';
import { SendMessagesComponent } from './list/send-messages/send-messages.component';
import {AmazingTimePickerModule} from 'amazing-time-picker';
import { CreateSubgroupComponent } from './list/create-subgroup/create-subgroup.component';
import { AddPermissionsComponent } from './list/add-permissions/add-permissions.component';
import { EditMyInfoComponent } from './list/edit-my-info/edit-my-info.component';
import { PersonalListComponent } from './list/personal-list/personal-list.component';
import { YellowPagesComponent } from './yellow-pages/yellow-pages.component';
import { PinPlaceComponent } from './pin-place/pin-place.component';
import { BusinessAdminComponent } from './business-admin/business-admin.component';
import { ListBasicDataComponent } from './list/list-basic-data/list-basic-data.component';
import { MyListSettingsComponent } from './list/my-list-settings/my-list-settings.component';
import { JoinGroupComponent } from './list/join-group/join-group.component';
import { DownloadDataComponent } from './list/download-data/download-data.component';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { BlankComponent } from './blank/blank.component';
import {AdminRoutingModule} from './admin/admin-routing.module';
import {AdminModule} from './admin/admin.module';
import { ReviewStatusChangeComponent } from './review-status-change/review-status-change.component';
import { SupportCausesComponent } from './support-causes/support-causes.component';

import { CauseDetailsComponent } from './cause-details/cause-details.component';
import { UserLiteRegistrationsComponent } from './list/user-lite-registrations/user-lite-registrations.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DonationSettingsComponent } from './list/donation-settings/donation-settings.component';
import { CustomMessageHelpComponent } from './list/custom-message-help/custom-message-help.component';
import { ReportedMessagesComponent } from './list/reported-messages/reported-messages.component';
import { PendingMessagesComponent } from './list/pending-messages/pending-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { CommentComponent } from './comment/comment.component';
import { ReplyComponent } from './reply/reply.component';
import {TopMenuModule} from "./top-menu/top-menu.module";
import {PageNotFoundModule} from "./page-not-found/page-not-found.module";
import {PaginationModule} from "./pagination/pagination.module";
import {UserHeaderModule} from "./user/user-header/user-header.module";
import {UserSidePanelModule} from "./user/user-side-panel/user-side-panel.module";
import {PrivateMessageModule} from "./private-message/private-message.module";



@NgModule({
  declarations: [
    AppComponent,
    TopStripComponent,
    FooterComponent,
    MemberDetailsComponent,
    // UrlComponent,
    UserSideMenuComponent,
    ReviewStatusChangeComponent,

    //   SampleCsvComponent,
    //   DownloadDataComponent,
    //   OtherpagesListsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AdminModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    GoogleChartsModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCDsqCNPcUe4UAEUaqoIN1IO41PwkxbC2o'
    }),
    ImageCropperModule,
    AmazingTimePickerModule,
    TopMenuModule,
    PageNotFoundModule,
    PaginationModule,
    UserHeaderModule,
    PrivateMessageModule
  ],
  providers: [HttpErrorHandler, MessageService, SingletonService, httpInterceptorProviders, AuthGuardService, SharedService,
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
