import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../api.service';
import {AuthService} from '../../auth.service';

@Component({
  selector: 'app-multiple-list-prefill-and-invite',
  templateUrl: './multiple-list-prefill-and-invite.component.html',
  styleUrls: ['./multiple-list-prefill-and-invite.component.css']
})
export class MultipleListPrefillAndInviteComponent implements OnInit {

  constructor(private api: ApiService, public auth: AuthService) { }
  public lists;
  public list_id;
  public admins;
  public list_details;
  public show_block = false;
  ngOnInit() {
    this.api.get_alllists().subscribe(res => {
      this.lists = res;
    });
  }
  get_list_data()
  {
    this.api.multiple_list_prefill_and_invite(this.list_id).subscribe(res => {
      this.admins = res['admins_data'];
      this.list_details = res['list_details'];
      this.show_block = true;
    });
  }

}
