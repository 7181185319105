import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";
import {User} from "../../model/user";

@Component({
  selector: 'app-update-user-details',
  templateUrl: './update-user-details.component.html',
  styleUrls: ['./update-user-details.component.css']
})
export class UpdateUserDetailsComponent implements OnInit {

  constructor(private api:ApiService, public auth: AuthService) { }
  public user_id:number;
  public user:User;
  public countries;
  public  show_user_data:boolean=false;
  public get_admin_user_data:boolean=false;

  public result: string = '';
  public success: boolean;

  ngOnInit() {
    this.user = new User()
    this.api.get_countries().subscribe(res=>{
      this.countries = res
    })
  }
  get_user_details(){
    this.get_admin_user_data=false
    this.api.get_admin_user_data(this.user_id).subscribe(res=>{

      console.log(res['fname'])
      this.user.id=res['id']
      console.log(this.user.id
      )
      this.user.fname=res['fname'];
      this.user.lname=res['lname'];
      this.user.email=res['email'];
      this.user.dob=res['dob'];
      this.user.mobile=res['mobile'];
      this.user.gender=res['gender'];
      this.user.profession=res['profession'];
      this.user.work_place=res['work_place'];
      this.user.zip=res['zip'];
      this.user.city=res['city'];
      this.user.country_id=res['country_id']
      this.user.address=res['address']
      this.get_admin_user_data=true
      this.show_user_data=true;
    })
  }
  update_data(){
    let post_data = {user: this.user}
    this.show_user_data=false;
    this.api.update_admin_user_data(post_data).subscribe(res=>{
      if(res['msg'] =='success'){
        this.result='User details have been updated'
        this.success=true;
      }else{
        this.result='You are not an admin'
        this.success=false;
      }

    }, error => {
      this.result = "There is some error while uploading data. Please try again later or contact 100pins support.";
      this.success = false;

    })
  }

}
