import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {SingletonService} from "../singleton";

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.css']
})
export class BlankComponent implements OnInit {

  constructor(private router: Router, private singleton: SingletonService) {

  }

  ngOnInit() {
    if(this.router.url == '/app'){
      window.location.href = 'https://app.100pins.com';
      return
    }else if(this.router.url == '/client-api-uniquecode-check'){
      window.location.href = this.singleton.root_url+'client-api-uniquecode-check';
      return;
    }else {
      let os = this.getMobileOperatingSystem();
      if (os == 'iOS') {
        window.location.href = "https://itunes.apple.com/in/app/100pins-free-group-messaging/id1120119117?mt=8";
        return;
      } else {
        window.location.href = "https://play.google.com/store/apps/details?id=com.pins100.v1";
        return
      }
    }
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  }
}
