import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './admin/admin.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {UserDetailsComponent} from './user-details/user-details.component';
import {MerchantBasicDetailsComponent} from './merchant-basic-details/merchant-basic-details.component';
// import {MerchantBasicdetailsByidComponent} from './merchant-basicdetails-byid/merchant-basicdetails-byid.component';
import {InsertMerchantsComponent} from './insert-merchants/insert-merchants.component';
import {MerchantDetailsComponent} from './merchant-details/merchant-details.component';
import {UpdateUserDetailsComponent} from './update-user-details/update-user-details.component';
import {UpdateUserImagesComponent} from './update-user-images/update-user-images.component';
import {ReviewStatusChangeComponent} from '../review-status-change/review-status-change.component';
import {InsertCityComponent} from './insert-city/insert-city.component';
import {InsertArticleComponent} from './insert-article/insert-article.component';
import {UpdateArticleComponent} from './update-article/update-article.component';
import {CreateCustomListsComponent} from './create-custom-lists/create-custom-lists.component';
import {PrefillInviteSingleUserComponent} from './prefill-invite-single-user/prefill-invite-single-user.component';
import {EmailMobileVerificationCodeComponent} from './email-mobile-verification-code/email-mobile-verification-code.component';
import {MultipleListPrefillAndInviteComponent} from './multiple-list-prefill-and-invite/multiple-list-prefill-and-invite.component';
import {OrphanReviewsComponent} from "./orphan-reviews/orphan-reviews.component";
import {DeleteOrphanReviewsComponent} from "./delete-orphan-reviews/delete-orphan-reviews.component";



const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: AdminDashboardComponent,
          },
          // Members related
          {path: 'merchant-basic-details', component: MerchantBasicDetailsComponent, },
          // {path: 'merchant-basicdetails-byid', component: MerchantBasicdetailsByidComponent, },
          {path: 'merchant-details', component: MerchantDetailsComponent, },
          {path: 'insert-merchants', component: InsertMerchantsComponent, },
          // City related
          {path: 'insert-city', component: InsertCityComponent, },
          // Articles related
          {path: 'insert-article', component: InsertArticleComponent, },
          {path: 'update-article', component: UpdateArticleComponent, },
          // Users related
          {path: 'user-details', component: UserDetailsComponent, },
          {path: 'update-user-details', component: UpdateUserDetailsComponent, },
          {path: 'update-user-images', component: UpdateUserImagesComponent, },
          {path: 'review-status-change', component: ReviewStatusChangeComponent, },
          // list
          {path: 'create-custom-list', component: CreateCustomListsComponent, },
          {path: 'single-list-prefill-and-invite', component: PrefillInviteSingleUserComponent, },
          {path: 'send-email-mobile-verification-code', component: EmailMobileVerificationCodeComponent, },
          {path: 'multiple-list-prefill-and-invite', component: MultipleListPrefillAndInviteComponent, },
          //orphan-reviews
          {path: 'display-orphan-reviews', component: OrphanReviewsComponent,},
          {path: 'delete-orphan-reviews', component: DeleteOrphanReviewsComponent,},

          // {
          //   path: 'display-orphan-reviews',
          //   loadChildren: () => import('./orphan-reviews/orphan-reviews.module').then(m => m.OrphanReviewsModule)
          // },

        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
