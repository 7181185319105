import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../api.service";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-update-user-images',
  templateUrl: './update-user-images.component.html',
  styleUrls: ['./update-user-images.component.css']
})
export class UpdateUserImagesComponent implements OnInit {

  constructor(private api:ApiService, public auth: AuthService) { }
  public user_id;
  public profilepic;
  public show_profile:boolean=false;
  public show_upload_image_form:boolean = false;
  public show_crop = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public name = '';
  public  new_picture;
  public image_changed:boolean=false;
  public result: string = '';
  public success: boolean;

  @ViewChild('image_file') image_file: ElementRef;

  ngOnInit() {
  }
  get_profilepic(){
    this.api.get_admin_profile_pic(this.user_id).subscribe(res=>{

      this.profilepic='https://s3.ap-south-1.amazonaws.com/100pins/gallery/userimages/profiles/'+res['img'];
      this.show_profile=true;
    })

  }
  public fileChangeEvent(fileInput: any,id){
    this.imageChangedEvent = fileInput;

    if (fileInput.target.files && fileInput.target.files[0]) {
      let type = fileInput.target.files[0]['type'];
      type = type.toLowerCase();
      console.log(type);
      if(type == 'image/jpeg' || type == 'image/jpg' || type == 'image/png') {
        if (fileInput.target.files[0]) {
          this.name = fileInput.target.files[0]['name'];
        }
        const reader = new FileReader();
        const ref = this;
        reader.onload = function (e: any) {
          ref.api.update_admin_profilepic(id,e.target.result).subscribe(data => {

           ref.new_picture=data['name'];
            ref.show_upload_image_form = false
            ref.show_crop = true
            ref.image_changed = true;
            // if (ref.image_changed) {
            //   ref.update_new_image()
            // }

          });
        };
        reader.readAsDataURL(fileInput.target.files[0]);
      }else{

      }
    }
  }
  update_new_image(){
    let post_data = {image:this.croppedImage, name: this.new_picture, id:this.user_id}
    this.api.admin_new_image_upload(post_data).subscribe(res => {

      this.show_crop = false
      if(res['name']){
        this.success = true;
        this.result = "Profile picture has been updated"

        this.profilepic='https://s3.ap-south-1.amazonaws.com/100pins/gallery/userimages/profiles/'+res['name']
        console.log(this.profilepic)
      }else{
        this.success = false;
        this.result = "You are not an admin"
      }
    }, error => {
      this.success = false;
      this.result = "There is some problem while updating your data. Please try again later"
    })
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
  }


}
