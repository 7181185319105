import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  constructor(private api:ApiService,public auth:AuthService) { }
  public input:any;
  public lists_data;
  public user;
  public show_details:boolean=false;

  ngOnInit() {
  }
  admin_user_details(id){
    this.api.get_admin_user_details(id).subscribe(res=>{
      this.show_details=true;
      this.lists_data=res['data'];
      this.user=res['user']

    })

  }

}
