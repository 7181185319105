import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-insert-article',
  templateUrl: './insert-article.component.html',
  styleUrls: ['./insert-article.component.css']
})
export class InsertArticleComponent implements OnInit {

  constructor(private api:ApiService, public auth: AuthService) { }
  public name;
  public des;
  public meta_des;
  public meta_title;
  public article;
  public key_words;
  public result: string = '';
  public success: boolean;

  ngOnInit() {
  }
  article_data(){
    if(!this.name || !this.des || !this.meta_des || !this.meta_title || !this.article || !this.key_words){
      alert('Please enter all the details');
      return;
    }
    let post_data={'name':this.name,'description':this.des,'meta_title':this.meta_title,'meta_description':this.meta_des,'article':this.article,'keywords':this.key_words}
    this.api.admin_insert_article(post_data).subscribe(res=>{
      if(res['success'] =='Y'){
       this.result='Article has been added'
        this.success=true;
      }else{
        this.result='You are not an admin'
        this.success=false;
      }

    }, error => {
      this.result = "There is some error while uploading data. Please try again later or contact 100pins support.";
      this.success = false;

    })

  }

}
