import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-update-article',
  templateUrl: './update-article.component.html',
  styleUrls: ['./update-article.component.css']
})
export class UpdateArticleComponent implements OnInit {

  constructor(private api:ApiService, public auth: AuthService) { }
  public article_id;
  public name;
  public des;
  public meta_des;
  public meta_title;
  public article;
  public key_words;
  public result: string = '';
  public success: boolean=false;
  public show_table: boolean=false;


  ngOnInit() {
  }
   get_article_data(){
    this.api.admin_get_article(this.article_id).subscribe(res=>{
      this.article_id=res['id'];
      this.name=res['name'];
      this.des=res['description'];
      this.meta_des=res['meta_description'];
      this.meta_title=res['meta_title'];
      this.article=res['article'];
      this.show_table=true;

    })
   }
update_article(){
  if(!this.name || !this.des || !this.meta_des || !this.meta_title || !this.article || !this.key_words){
    alert('Please enter all the details');
    return;
  }
  let post_data={'id':this.article_id,'name':this.name,'description':this.des,'meta_title':this.meta_title,'meta_description':this.meta_des,'article':this.article,'keywords':this.key_words}

    this.api.admin_update_article(post_data).subscribe(res=>{
      if(res['success'] =='Y'){
        this.result='Article has been updated'
        this.success=true;
      }else{
        this.result='You are not an admin'
        this.success=false;
      }

    }, error => {
      this.result = "There is some error while uploading data. Please try again later or contact 100pins support.";
      this.success = false;
    })
}
}
