import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prefill-invite-single-user',
  templateUrl: './prefill-invite-single-user.component.html',
  styleUrls: ['./prefill-invite-single-user.component.css']
})
export class PrefillInviteSingleUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
