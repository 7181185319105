import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {UserList} from './model/user_list';
import {User} from './model/user';
import {SingletonService} from './singleton';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private handleError: HandleError;
  constructor(private http: HttpClient, private singleton: SingletonService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('HeroesService');
  }
  get_reviews (page, city_id= 0): Observable<Object> {
    return this.http.get<Object>(this.singleton.root_url + 'api/reviews' + '?city_id=' + city_id + '&page=' + page, httpOptions);
  }
  do_login(username, password): Observable<Object> {
    const post_data = {
      'username': username,
      'password': password
    };

    return this.http.post(this.singleton.root_url + 'api/login',
      post_data,
      httpOptions).pipe(
      catchError(this.handleError<Object>('do_login', {}))
    );
  }

  get_lists(): Observable<UserList[]> {
    return  this.http.get<UserList[]>(this.singleton.root_url + 'api/get/lists');
  }

  get_user_lists(): Observable<UserList[]> {
    return  this.http.get<UserList[]>(this.singleton.root_url + 'api/lists');
  }

  get_countries(): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/countries');
  }

  get_countries_by_alphabet(letter): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/countries/' + letter);
  }
  get_cities_in_country(country_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/cities/' + country_id);
  }

  get_cities_by_alphabet(country_id, cityletter): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/citiesbyalpha/' + country_id + '/' + cityletter);
  }

  fetch_list_members(post_data): Observable<Object> {
    return this.http.post(this.singleton.root_url + 'api/lists/members-browse', post_data);
  }
  get_list_data(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/list/data?list_url=' + list_url);
  }

  get_topstrip(list_url): Observable<object> {
    if(list_url)
      return this.http.get(this.singleton.root_url + 'api/get/topstrip?list_url=' + list_url);
    else
      return this.http.get(this.singleton.root_url + 'api/get/topstrip');
  }
  get_list_header(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/list/header?list_url=' + list_url);
  }
  get_side_menu(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/sidepanel?list_url=' + list_url);
  }
  get_list_fields(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/fields?list_url=' + list_url);
  }
  get_preset_values(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/get/values', post_data, httpOptions);
  }
  search_members(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/search/members', post_data, httpOptions);
  }
  remove_connection(member_1, member_2): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/remove/connection?member_1=' + member_1 + '&member_2=' + member_2);
  }
  getAliasNames (post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/aliases/', post_data, httpOptions);
  }
  send_private_message(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/compose/message', post_data, httpOptions);
  }

  get_member_data( member_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/lists/member/' + member_id);
  }

  edit_member(member_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/edit/member/' + member_id, post_data, httpOptions);
  }

  get_pending_members(name, list_url, page, sort): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/pending/members?list_url=' + list_url + '&page=' + page + '&name=' + name + '&sort=' + sort);
  }

  approve_member(list_id, id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/approve/member/' + list_id + '/' + id);
  }
  disapprove_member(list_id, id): Observable<object> {
      return this.http.get(this.singleton.root_url + 'api/list/disapprove/member/' + list_id + '/' + id);
  }
  subgroups(list_url, page): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/subgroups?list_url=' + list_url + '&page=' + page);
  }

  get_members_count(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/get/members/from/fields', post_data, httpOptions);
  }

  edit_subgroup(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/edit/subgroup', post_data, httpOptions);
  }

  get_attendance_dashboad_data(list_url, range): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/attendance-dashboard?list_url=' + list_url + '&range=' + range);
  }

  get_attendance_categories(list_url, page, search_course= ''): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/attendance/categories?list_url=' + list_url + '&page=' + page + '&search_course=' + search_course);
  }

  add_category(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/add/category', post_data, httpOptions);
  }

  list_settings(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/settings?list_url=' + list_url);
  }

  get_admins(list_id, name= ''): Observable<object> {
    return this.http.get(this.singleton.root_url + '/list/admins/' + list_id + '?name=' + name);
  }

  get_permissions(list_url, page, type= ''): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/permissions?list_url=' + list_url + '&page=' + page + '&type=' + type);
  }

  delete_permission(permission_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/delete/permission/' + permission_id);
  }

  get_attendance_data(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/attendance/data?list_url=' + list_url);
  }

  attendance_search(list_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/attendance/search/' + list_id, post_data, httpOptions);
  }

  search_attendance(list_id, post_data, page, result_count= 20, download= 'N'): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/search/attendance/' + list_id + '?page=' + page + '&download=' + download, post_data, httpOptions);
  }

  list_fields(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/fields?list_url=' + list_url);
  }

  update_field(field_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/list/update/field/' + field_id, post_data, httpOptions);
  }

  add_preset_values(field_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/list/add/presets/' + field_id, post_data, httpOptions);
  }

  add_new_field(list_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/list/add/field/' + list_id, post_data, httpOptions);
  }

  list_see_messages(list_url, page): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/see/messages?list_url=' + list_url + '&page=' + page);
  }

  list_delivery_stats(message_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/list/delivery/reports/' + message_id);
  }

  delivery_reports(message_id, type, page, download): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/sent/messages/delivery/' + message_id + '/' + type + '?page=' + page+'&download='+download);
  }

  like_message(message_id, unlike): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/message/like/' + message_id + '?unlike=' + unlike);
  }

  inbox_messages(page, list_url= false): Observable<object> {
    if (list_url) {
      return this.http.get(this.singleton.root_url + 'api/inbox?list_url=' + list_url + '&page=' + page);
    } else {
      return this.http.get(this.singleton.root_url + 'api/inbox?page=' + page);
    }
  }

  user_sent_mail(page, list_url= false): Observable<object> {
    if (list_url) {
      return this.http.get(this.singleton.root_url + 'api/sent-mail?list_url=' + list_url + '&page=' + page);
    } else {
      return this.http.get(this.singleton.root_url + 'api/sent-mail?page=' + page);
    }
  }

  pin_user(user_id, unpin): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/pin/user/' + user_id + '?unpin=' + unpin);
  }

  get_message(id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/message/' + id);
  }

  check_url_exists(url, page= 1, type= 'ws'): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/check/url/' + url + '?page=' + page + '?type=' + type);
  }

  get_user_header(user_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/user/header/' + user_id);
  }

  get_user_pins(user_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/user/reviews/' + user_id);
  }

  get_category_reviews(category, city, page): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/category/reviews/' + category + '/' + city + '?page=' + page);
  }

  change_picture(image): Observable<Object> {
    return this.http.post(this.singleton.root_url + 'api/change/picture', {'image': image}, httpOptions);
  }

  custom_file_upload(image): Observable<Object> {
    return this.http.post(this.singleton.root_url + 'api/upload/list/images', {'image': image}, httpOptions);
  }

  get_city_suggestions(city, country): Observable<Object> {
    return this.http.get(this.singleton.root_url + 'api/city/suggestions?city=' + city + '&country=' + country);
  }

  get_merchant_suggestions(search, city_id= ''): Observable<Object> {
    return this.http.get(this.singleton.root_url + 'api/get/merchant/suggestions?search=' + search + '&city_id=' + city_id);
  }

  register_user(post_data): Observable<Object> {
    return this.http.post(this.singleton.root_url + 'api/register/user', post_data);
  }

  get_matching_lists(search): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/matching/lists?search=' + search);
  }

  check_list(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/check/list?list_url=' + list_url);
  }

  email_mobile_code_request(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/request/otp/email/mobile', post_data);
  }

  email_mobile_code_verify(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/verify/otp/email/mobile', post_data);
  }

  check_list_member(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/check/list/member?list_url=' + list_url);
  }

  check_list_exists(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/check/list/exists?list_url=' + list_url);
  }

  // get_people_pinned_by(key= 'date', order= 'DESC'): Observable<object> {
  //   return this.http.get(this.singleton.root_url + 'api/people/pinning?sort=' + key + '&order=' + order);
  // }
  support_causes_side_panel(list_url): Observable<object>{
    return this.http.get(this.singleton.root_url+'api/support/causes/side?list_url='+list_url)
  }

  get_support_causes(list_url, group_id): Observable<object>{
    return this.http.get(this.singleton.root_url+'api/get/support/causes?list_url='+list_url+'&group_id='+group_id)
  }

  donation_settings(list_url): Observable<object>{
    return this.http.get(this.singleton.root_url+'api/donation/settings?list_url='+list_url)
  }

  get_cause_details(cause_id): Observable<object>{
    return this.http.get(this.singleton.root_url+'api/get/cause/details/'+cause_id)
  }

  add_payment_temp(list_id, post_data){
    return this.http.post(this.singleton.root_url+'api/add/payment/temp/'+list_id, post_data)
  }

  add_payment(list_id, post_data){
    return this.http.post(this.singleton.root_url+'api/add/payment/'+list_id, post_data)
  }

  get_users_lite_data(list_url, page, download='N'): Observable<object>{
    return this.http.get(this.singleton.root_url+'api/get/user/lite/data?list_url='+list_url+'&page='+page+'&download='+download)
  }

  get_people_pinned_by(key='date', order='DESC'): Observable<object> {
    return this.http.get(this.singleton.root_url+'api/people/pinning?sort='+key+'&order='+order)
  }

  update_user (user: User): Observable<User> {
    return this.http.put<User>(this.singleton.root_url + 'api/update/user', user, httpOptions);
  }

  image_upload(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/image/upload', post_data);
  }

  change_password(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/change/password', post_data);
  }

  update_password(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/password', post_data);
  }

  get_secret_questions(): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/secret/questions');
  }

  update_secret_questions(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/secret/questions', post_data);
  }

  get_default_settings(): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/default/settings');
  }

  update_default_settings(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/default/settings', post_data);
  }

  get_user_lists_and_settings(list_url = false): Observable<object> {
    if (list_url) {
      return this.http.get(this.singleton.root_url + 'api/user/lists/settings?list_url=' + list_url);
    } else {
      return this.http.get(this.singleton.root_url + 'api/user/lists/settings');
    }
  }

  get_sms_balance(list_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/sms/balance/' + list_id);
  }

  group_message(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/group/message', post_data);
  }

  get_matching_members_count(list_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/check/sms/balance?list_id=' + list_id, post_data);
  }

  file_upload(image, ext, list_id, custom_data= 'N'): Observable<Object> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('ext', ext);
    const options = {
      reportProgress: true,
    };
    return this.http.post(this.singleton.root_url + 'api/file/upload?list_id=' + list_id + '&custom_data=' + custom_data + '&size=' + image['size']/1000 , formData, options);
  }

  send_promotional_message(post_data) {
    return this.http.post(this.singleton.root_url + 'api/send/promotionalsms', post_data);
  }

  create_subgroup(list_id, post_data) {
    return this.http.post(this.singleton.root_url + 'api/create/subgroup/' + list_id, post_data, httpOptions);
  }

  get_auto_suggested_users(list_id, search) {
    return this.http.get(this.singleton.root_url + 'api/auto/suggested/users/' + list_id + '?search=' + search);
  }

  get_own_subgroups(list_id) {
    // return this.http.get(this.singleton.root_url + 'api/list/own/folders/' + list_id);
    return this.http.get(this.singleton.root_url + 'api/list/common/folders/' + list_id);
  }

  create_subgroup_folder(list_id, post_data) {
    return this.http.post(this.singleton.root_url + 'api/create/folder/' + list_id, post_data);
  }

  get_members_autocomplete(list_id, post_data) {
    return this.http.post(this.singleton.root_url + 'api/list/get/members/autocomplete?list_id=' + list_id, post_data);
  }

  add_permission(list_id, post_data) {
    return this.http.post(this.singleton.root_url + 'api/add/permission/' + list_id, post_data);
  }

  get_my_info(list_url) {
    return this.http.get(this.singleton.root_url + 'api/get/all/data?list_url=' + list_url);
  }

  edit_list_member_data(member_id, post_data) {
    return this.http.post(this.singleton.root_url + 'api/edit/list/member/' + member_id, post_data);
  }

  forum_message(post_data){
    return this.http.post(this.singleton.root_url+'api/forum/message', post_data)
  }

  get_replies(id, page){
    return this.http.get(this.singleton.root_url+'api/get/replies/'+id+'?page='+page)
  }

  browse_merchants(order, city= '', category, page= 1) {
    return this.http.get(this.singleton.root_url + 'api/yellow-pages?page=' + page + '&order=' + order + '&city=' + city + '&category=' + category);
  }

  child_categories(category_id) {
    return this.http.get(this.singleton.root_url + 'api/child/categories/' + category_id);
  }

  get_tags(list_url): Observable<object>{
    return this.http.get(this.singleton.root_url+'get/tags/'+list_url+'?type=url')
  }

  get_user_email_notifications(): Observable<object> {
    return  this.http.get(this.singleton.root_url + 'api/email_notifications');
  }

 update_options(data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/emailnotifications', data);
  }
  update_listsettings(listdata): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/listsettings/' + listdata.id, listdata);
  }
  get_alllists(): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/all_lists');
  }
  get_own_lists(): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/own/lists');
  }
  get_personal_list_settings(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/personal_list_settings?list_url=' + list_url);
  }

  post_review(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/post/review', post_data);
  }
  check_user_exists(user_data) {
    return this.http.post(this.singleton.root_url + 'api/check/user', user_data);
  }
  forgot_password_web(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/forgot/password/web', post_data);
  }
  new_password_web(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/new/password/web', post_data);
  }
  get_list_basicdata(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/list/basicdata?list_url=' + list_url);
  }
  update_list_basicdata(data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/list/basicdata', data);
  }

  delete_member(member_id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/delete/member/' + member_id);
  }

  my_list_settings(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/my/list/settings?list_url=' + list_url);
  }

  update_my_list_settings(list_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/update/my/list/settings/' + list_id, post_data);
  }

  check_list_membertypes(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/check/list/membertypes?list_url=' + list_url);
  }

  add_user_to_list(list_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/add/user/list/' + list_id, post_data);
  }

  get_membertypes(list_url): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/membertypes?list_url=' + list_url);
  }

  download_data(list_id, post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + 'api/download/data/' + list_id, post_data);
  }
  get_admin_user_details(id) {
    return this.http.get(this.singleton.root_url + 'api/admin/user-details/' + id);
  }
  get_admin_user_data(id) {
    return this.http.get(this.singleton.root_url + 'api/admin/user/data/' + id);
  }
  update_admin_user_data (post_data) {
    return this.http.post(this.singleton.root_url + 'api/update/admin/user', post_data, httpOptions);
  }
  get_admin_profile_pic(id) {
    return this.http.get(this.singleton.root_url + 'api/admin/profile/pic/' + id);
  }
  update_cause(cause_id, post_data){
    return this.http.post(this.singleton.root_url+'api/update/cause/'+cause_id, post_data)
  }
  update_cause_image(post_data): Observable<object>{
    return this.http.post(this.singleton.root_url+'api/update/cause/image', post_data)
  }
  create_support_cause(post_data): Observable<object>{
    return this.http.post(this.singleton.root_url+'api/create/support/cause', post_data)
  }
  update_admin_profilepic(id, image): Observable<Object> {
    return this.http.post(this.singleton.root_url + 'api/update/admin/profilepic/' + id, {'image': image}, httpOptions);
  }
  admin_new_image_upload(post_data): Observable<object> {
    return this.http.post(this.singleton.root_url + '/api/admin/newimage/upload', post_data);
  }
  get_subgroup_members_count(id): Observable<object> {
    return this.http.get(this.singleton.root_url + 'api/get/subgroup/count/' + id);
  }
  admin_insert_city(post_data) {
    return this.http.post(this.singleton.root_url + 'api/admin/insert/city', post_data);
  }
  get_categories() {
    return this.http.get(this.singleton.root_url + 'api/get/categories');
  }
  admin_insert_merchant(post_data) {
    return this.http.post(this.singleton.root_url + 'api/admin/insert/merchant', post_data);
  }

  admin_insert_article(post_data) {
    return this.http.post(this.singleton.root_url + 'api/admin/insert/article', post_data);
  }
  admin_get_article(id) {
    return this.http.get(this.singleton.root_url + 'api/admin/get/article/' + id);
  }
  admin_update_article(post_data) {
    return this.http.post(this.singleton.root_url + 'api/admin/update/article', post_data);
  }
  get_merchants() {
    return this.http.get(this.singleton.root_url + 'api/get/merchants');
  }
  admin_insert_list(post_data) {
    return this.http.post(this.singleton.root_url + 'api/admin/insert/list', post_data);
  }
  approve_multiple_members(data) {
    return this.http.post(this.singleton.root_url + 'api/approve/multiple/members', data);
  }
  disapprove_multiple_members(data) {
    return this.http.post(this.singleton.root_url + 'api/disapprove/multiple/members', data);
  }
  get_merchant_details(id) {
    return this.http.get(this.singleton.root_url + 'api/get/merchant/details/' + id);
  }
  get_merchant_details_byname(merchant_letter){
    return this.http.get(this.singleton.root_url + 'api/get/merchant-details?merchant_letter=' + merchant_letter);
  }
  prefill_invite_user(post_data) {
    return this.http.post(this.singleton.root_url + 'api/prefill/invite/user', post_data);
  }
  invite_user_profilepic(image) {
    return this.http.post(this.singleton.root_url + 'api/invite/user/profilepic/', {'image': image}, httpOptions);
  }
  multiple_list_prefill_and_invite(list_id) {
    return this.http.get(this.singleton.root_url + 'api/get/list/data/' + list_id);
  }

  verify_captcha(data){
    return this.http.post(this.singleton.root_url+'api/verify/captcha', data)
  }

  support_message(data){
    return this.http.post(this.singleton.root_url+'api/support/no_login', data)
  }

  list_file_upload(image_data, url, num){
    return this.http.post<Object>(this.singleton.root_url+'api/list-file/upload?logo_num='+num+'&url='+url,image_data)
  }

  get_orphan_reviews(page){
    return this.http.get(this.singleton.root_url+'api/get/orphan_reviews?page='+page);
  }

  get_unlisted_merchants(page){
    return this.http.get(this.singleton.root_url+'api/get/unlisted_merchants?page='+page);
  }

  insert_into_reviews(orphan){
    return this.http.post(this.singleton.root_url+'api/insert/reviews',orphan);
  }
  delete_orphan_reviews(delete_ids){
    return this.http.post(this.singleton.root_url+'api/delete-orphan-reviews',delete_ids)
  }

  delete_list_msg(conversation_id){
    return this.http.get(this.singleton.root_url+'api/delete/list/message?id='+conversation_id)
  }

  user_actions_logging(post_data){
    return this.http.post(this.singleton.root_url+'api/100pins_logging', post_data, httpOptions);
  }




}


