import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.css']
})
export class MerchantDetailsComponent implements OnInit {

  @Input('pageOffset') pageOffset: number;
  @Input('pageIndex') pageIndex: number;

  length: number;
  unlisted_merchants = [];
  public from: number;
  public to: number;
  public total: number = 0;
  public perPage: number = 25;
  public page: number = 1;
  public merchant_id: number;
  public last_page: number;
  public ghg;

  constructor(private api:ApiService, private route:ActivatedRoute, public router:Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.hasOwnProperty('page')) {
        this.page = Number(queryParams['page']);
      } else {
        this.page = 1;
      }
    })

    this.api.get_unlisted_merchants(this.page).subscribe(res=>{
      this.unlisted_merchants = res['data'];
      this.total = res['total'];
      this.from = res['from'];
      this.to = res['to'];
      this.last_page = res['last_page'];
    })
  }

  submit(merchant){
    this.router.navigate(['/admin/insert-merchants'],{queryParams:{'name': merchant['name'],'area':merchant['location'],'city':merchant['city'],'country':merchant['country']}})
  }
}
