import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('login') !== -1) {
        console.log("coming here");
      return next.handle(req);
    }
    if(!this.auth.getAuthorizationToken()){
      return next.handle(req);
    }
    // Get the auth token from the service.
    const authToken = this.auth.getAuthorizationToken();
    // console.log("going next");
    /*
    * The verbose way:
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', authToken)
    });
    */
    // Clone the request and set the new header in one step.
    const authReq = req.clone({ setHeaders: { Authorization: 'Bearer '+authToken } });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
