import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-delete-orphan-reviews',
  templateUrl: './delete-orphan-reviews.component.html',
  styleUrls: ['./delete-orphan-reviews.component.css']
})
export class DeleteOrphanReviewsComponent implements OnInit {

  ids:string;

  constructor(private api:ApiService, private route:ActivatedRoute) { }

  ngOnInit(): void {
  }
  submit(ids){
    let delete_ids = ids.split(',');
    console.log(delete_ids);
    this.api.delete_orphan_reviews(delete_ids).subscribe(res=>{
      console.log(res);
      if(res['msg'] == "success")
        window.alert("Deleted successfully");
    })
    delete_ids = [];

  }

}
