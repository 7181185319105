import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-email-mobile-verification-code',
  templateUrl: './email-mobile-verification-code.component.html',
  styleUrls: ['./email-mobile-verification-code.component.css']
})
export class EmailMobileVerificationCodeComponent implements OnInit {

  constructor(private api: ApiService, private auth: AuthService, private router: Router) { }
  public email;
  public mobile;
  public result: string = '';
  public success: boolean;

  ngOnInit() {
  }
  submit(){
    console.log('hi')
    let  purpose='verification'
    let  country_isd_code=91;
    let post_data = {'isd':country_isd_code,'mobile':this.mobile,'purpose':purpose,'request_otp':'Y'};
    this.api.forgot_password_web(post_data).subscribe(res=>{
      if(res['success']=='Y'){
        this.result='OTP sent'
        this.success=true;
      }
    })

  }

}
