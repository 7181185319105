import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { MerchantBasicDetailsComponent } from './merchant-basic-details/merchant-basic-details.component';
// import { MerchantBasicdetailsByidComponent } from './merchant-basicdetails-byid/merchant-basicdetails-byid.component';
import { InsertMerchantsComponent } from './insert-merchants/insert-merchants.component';
import { MerchantDetailsComponent } from './merchant-details/merchant-details.component';
import { UpdateUserDetailsComponent } from './update-user-details/update-user-details.component';
import { UpdateUserImagesComponent } from './update-user-images/update-user-images.component';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AmazingTimePickerModule} from 'amazing-time-picker';
import {ImageCropperModule} from 'ngx-image-cropper';
import { InsertCityComponent } from './insert-city/insert-city.component';
import { InsertArticleComponent } from './insert-article/insert-article.component';
import { UpdateArticleComponent } from './update-article/update-article.component';
import { CreateCustomListsComponent } from './create-custom-lists/create-custom-lists.component';
import { PrefillInviteSingleUserComponent } from './prefill-invite-single-user/prefill-invite-single-user.component';
import { EmailMobileVerificationCodeComponent } from './email-mobile-verification-code/email-mobile-verification-code.component';
import { MultipleListPrefillAndInviteComponent } from './multiple-list-prefill-and-invite/multiple-list-prefill-and-invite.component';
import { OrphanReviewsComponent } from './orphan-reviews/orphan-reviews.component';
import {PaginationModule} from "../pagination/pagination.module";
import { DeleteOrphanReviewsComponent } from './delete-orphan-reviews/delete-orphan-reviews.component';


@NgModule({declarations: [AdminComponent, UserDetailsComponent, AdminDashboardComponent, MerchantBasicDetailsComponent, InsertMerchantsComponent, MerchantDetailsComponent, UpdateUserDetailsComponent, UpdateUserImagesComponent, InsertCityComponent, InsertArticleComponent, UpdateArticleComponent, CreateCustomListsComponent, PrefillInviteSingleUserComponent, EmailMobileVerificationCodeComponent, MultipleListPrefillAndInviteComponent, OrphanReviewsComponent, DeleteOrphanReviewsComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    AmazingTimePickerModule,
    ImageCropperModule,
    PaginationModule,
  ]
})
export class AdminModule { }
