import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.css']
})
export class MemberDetailsComponent implements OnInit {

  constructor(private api: ApiService) { }
  @Input() list_id;
  @Input() user;
  @Input() member_types;
  @Input() fields;
  @Input() editable_members;
  @Input() delete_members;
  @Input() send_message_members;
  @Input() connected_member_ids;
  @Input() connected_members_data;
  @Input() userType;
  @Input() list_url;

  @Input() show_message_form = [];
  ngOnInit() {
    for(let i=0; i <this.user['list_member_ids'].length;i++){
      this.show_message_form[this.user['list_member_ids'][i]] = false
    }
    console.log(this.userType)
  }

  remove_connection(member_1, member_2) {
    this.api.remove_connection(member_1, member_2).subscribe(res=>{
      console.log(res);
    })
  }

}
