import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";
import {SingletonService} from "../../singleton";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-create-custom-lists',
  templateUrl: './create-custom-lists.component.html',
  styleUrls: ['./create-custom-lists.component.css']
})
export class CreateCustomListsComponent implements OnInit {

  constructor(private api: ApiService, public auth: AuthService, public singleton: SingletonService, private route: ActivatedRoute) { }
  public name;
  public description;
  public owner_name;
  public member_approval;
  public type;
  public merchants;
  public merchant_id;
  public list_type;
  public country_id;
  public city_id;
  public countries;
  public cities;
  public email;
  public area;
  public logo_1;
  public logo_2;
  public url;
  public logo_on_top = 'N';
  public signup_qstn;
  public signup_ans;
  public request_heading;
  public request_text;
  public meta_title;
  public meta_desc;
  public fb_title;
  public fb_desc;
  public wel_email_text;
  public invite_email_sub;
  public invite_email_text1;
  public invite_email_text2;
  public list_payments = 'N';
  public payments_optional = 'N';
  public payment_later_note;
  public payment_screen_heading;
  public payment_screen_intro_text;
  public payment_ack_email_text;
  public list_accepts_lite_signups = 'N';
  public full_signup_reasoning_text;
  public lite_signup_reasoning_text;
  public url_to_call_after_signup;
  public api_key_for_url_call;
  public value_to_pass_via_url;
  public redirect_path_after_signup;
  public allowed_direct_user_signups = 'N';
  public result: string = '';
  public success: boolean =false;
  public list_logo_1;
  public list_logo_2
  public filetoUpload;

  ngOnInit() {
    this.api.get_countries().subscribe(res=>{
      this.countries = res
    })
    this.api.get_merchants().subscribe(res=>{
      this.merchants = res;
      console.log(this.merchants['id'])
    })
  }
  get_cities(country_id) {
    this.api.get_cities_in_country(country_id).subscribe(res=>{
        this.cities=res;
    });
  }
  submit_list_data(){
      // if(!this.name || !this.description || !this.owner_name || !this.url || !this.member_approval || !this.type || !this.merchant_id
      //   || !this.list_payments || !this.country_id || !this.city_id || !this.area || !this.email || !this.logo_1 || !this.logo_2){
      //   alert('Please enter all the details');
      //   return
      // }
    let post_data={
      'name':this.name,
      'description':this.description,
      'owner':this.owner_name,
      'url':this.url,
      'member_approval':this.member_approval,
      'type':this.type,
      'merchant':this.merchant_id,
      'list_type':this.list_type,
      'country_id':this.country_id,
      'city_id':this.city_id,
      'area':this.area,
      'email':this.email,
      'logo_1':this.logo_1,
      'logo_2':this.logo_2,
      'logo_on_top':this.logo_on_top,
      'signup_qstn':this.signup_qstn,
      'signup_ans':this.signup_ans,
      'request_heading':this.request_heading,
      'request_text':this.request_text,
      'meta_title':this.meta_title,
      'meta_desc':this.meta_desc,
      'fb_title':this.fb_title,
      'fb_desc':this.fb_desc,
      'wel_email_text':this.wel_email_text,
      'invite_email_sub':this.invite_email_sub,
      'invite_email_text1':this.invite_email_text1,
      'invite_email_text2':this.invite_email_text2,
      'list_payments':this.list_payments,
      'payments_optional':this.payments_optional,
      'payment_later_note':this.payment_later_note,
      'payment_screen_heading':this.payment_screen_heading,
      'payment_screen_intro_text':this.payment_screen_intro_text,
      'payment_ack_email_text':this.payment_ack_email_text,
      'list_accepts_lite_signups':this.list_accepts_lite_signups,
      'full_signup_reasoning_text':this.full_signup_reasoning_text,
      'lite_signup_reasoning_text':this.lite_signup_reasoning_text,
      'url_to_call_after_signup':this.url_to_call_after_signup,
      'api_key_for_url_call':this.api_key_for_url_call,
      'value_to_pass_via_url':this.value_to_pass_via_url,
      'redirect_path_after_signup':this.redirect_path_after_signup,
      'allowed_direct_user_signups':this.allowed_direct_user_signups,
    }
    this.api.admin_insert_list(post_data).subscribe(res=>{
      if(res['msg'] == 'success'){
        this.result = 'List has been added successfully';
        this.success = true
      }
      else {
        this.result='You are not an admin'
        this.success=false;
      }
      },error => {
          this.result = "There is some error while uploading data. Please try again later or contact 100pins support.";
          this.success = false;
    })
  }

  fileUpload(num, event) {
    this.filetoUpload=<File>event.target.files[0]
    const fd=new FormData();
    fd.append("image", this.filetoUpload);
    this.api.list_file_upload(fd, this.url, num).subscribe(res => {
      this.logo_1 = res['name']
      this.logo_2 = res['name']
    })
  }

  static get_ext(filename){
    return '.'+filename.split('.').pop();
  }

}
