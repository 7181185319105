import { Injectable } from '@angular/core';
import {User} from './model/user';
import {UserList} from './model/user_list';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User;
  public list : UserList;
  public months  = [
    {id: 1, name: 'Jan', full_name: 'January'},
    {id: 2, name: 'Feb', full_name: 'February'},
    {id: 3, name: 'Mar', full_name: 'March'},
    {id: 4, name: 'Apr', full_name: 'April'},
    {id: 5, name: 'May', full_name: 'May'},
    {id: 6, name: 'Jun', full_name: 'June'},
    {id: 7, name: 'Jul', full_name: 'July'},
    {id: 8, name: 'Aug', full_name: 'August'},
    {id: 9, name: 'Sep', full_name: 'September'},
    {id: 10, name: 'Oct', full_name: 'October'},
    {id: 11, name: 'Nov', full_name: 'November'},
    {id: 12, name: 'Dec', full_name: 'December'},
    ];

  constructor(private router: Router) { }
  getAuthorizationToken() {
    return localStorage.getItem('token');
  }
  setAuthorizationToken(token) {
    localStorage.setItem('token', token);
  }
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('list');
    localStorage.removeItem('count');
    this.router.navigate(['/login'])
  }
  get_user(): User {
    if (this.user){
      return this.user;
    }
    if (localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user')) as User;
      return this.user;
    } else {
      return null;
    }
  }
  set_user(user) {
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user as User;
  }
  set_token(token) {
    localStorage.setItem('token', token);
  }
  set_list(list){
    localStorage.setItem('list', JSON.stringify(list));
    this.list = list as UserList;
  }
  get_list(): UserList {
    if (this.list) {
      return this.list;
    }
    if (localStorage.getItem('list')){
      this.list = JSON.parse(localStorage.getItem('list')) as UserList;
      return this.list;
    } else {
      return null;
    }
  }
  generate_numbers(start, end){
    let numbers = [];
    for(let i=start; i<=end;i++){
      numbers.push(i)
    }
    return numbers
  }

  async get_error_response(error:HttpErrorResponse) {
    let status = 'Error';
    let message = 'There might be some problem. Please try again later. If the problem continues, please contact 8106890213.';

    if (error.status == 401)
      message = 'Please login again to continue'

    if (error.status == 401) {
      this.logout();
    }

  }

}
