import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user-side-menu',
  templateUrl: './user-side-menu.component.html',
  styleUrls: ['./user-side-menu.component.css']
})
export class UserSideMenuComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      if(params.hasOwnProperty(('name')))
        this.name = params['name']
    })
  }
  @Input() list_url:string;
  public name: string=''
  ngOnInit() {
  }

  navigate(){
    if(this.name){
      this.router.navigate(['/lists', this.list_url, 'members-browse'], {queryParams: {name: this.name}})
    }
  }
}
