import {Component, OnInit, Input} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {ApiService} from '../api.service';
import {SingletonService} from '../singleton';
import {SharedService} from '../shared-service';

@Component({
  selector: 'app-top-strip',
  templateUrl: './top-strip.component.html',
  styleUrls: ['./top-strip.component.css']
})
export class TopStripComponent implements OnInit {

  constructor(private api: ApiService, public auth: AuthService, public singleton: SingletonService, private route: ActivatedRoute, private router: Router, private shared_service: SharedService) {
  }

  @Input() list_url: string;

  public pinned_by_count: number;
  public pins_count: number;
  public user = [];
  public show_user_block: boolean = false;
  public pinned_by: number = 0;
  public url = [];
  public show_countries: boolean = false;
  public countries;
  public cities;
  public profile:any;
  public within_country = {};
  public show_cities: boolean = false;
  public letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];


  getAlphabet(letter = 'A') {
    this.api.get_countries_by_alphabet(letter).subscribe(
      countries => {
        this.countries = countries;
      }
    );
  }


  get_cities(cntry) {
    console.log(cntry);
    this.api.get_cities_in_country(cntry.id).subscribe(
      cities => {
        console.log(cities);
        this.cities = cities;
        this.within_country = cntry;
      });
    this.show_cities = true;
    this.show_countries = false;
  }

  citiesbyAlpha(cnt, alpha) {
    this.api.get_cities_by_alphabet(cnt.id, alpha).subscribe(
      cities => {
        this.cities = cities;
      });
  }

  close() {
    this.show_countries = false;
  }

  closecities() {
    this.show_cities = false;
  }

  ngOnInit() {
    if (this.auth.getAuthorizationToken()) {
      this.get_data();
    }
    this.shared_service.userChange$.subscribe(data => {
      this.get_data();
    });
    this.getAlphabet('A');
  }

  get_data() {
    // this.profile="https://s3.ap-south-1.amazonaws.com/100pins/gallery/userimages/profiles/"
    this.api.get_topstrip(this.list_url).subscribe(res => {
      this.pinned_by_count = res['pinned_by'];
      this.pins_count = res['pins_count'];
      this.user = res['user'];
      if(this.user['picture']){
        this.profile='https://s3.ap-south-1.amazonaws.com/100pins/gallery/userimages/thumbnails/'+this.user['picture'];
      }else{
        if(res['usr']['gender']=='F'){
          this.profile='../../../assets/imgs/profile-women.jpg'
        }else{
          this.profile='../../../assets/imgs/profile-men.jpg'
        }
      }

      this.url = res['url'];
      this.auth.set_user(res['usr']);

      if (this.list_url && !this.auth.get_user().mobile_authenticated) {
        this.router.navigate(['/verify']);
      }
    }, error => {
      if (error.status == 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('list');
        this.router.navigate(['/login'], {queryParams: {return: this.router.url}})
      }
    });

  }

  logout() {
    this.show_user_block = false;
    this.auth.logout();
  }
}
