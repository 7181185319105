import { Injectable } from '@angular/core';

@Injectable()
export class SingletonService {
  //public root_url = 'http://127.0.0.1:8000/';
  //public root_url = 'https://api-dev.100pins.com/';
  // public root_url = 'http://localhost:9001/';
  //  public root_url = 'http://172.17.0.2/';

  // public root_url = 'https://api.100pins.com:4000/';

  public root_url = 'https://api.100pins.com/';
  public ws_url = 'wss://ws-dev.100pins.com:8081/';
  // public ws_url = 'ws://localhost:8081/';
  // public root_url = 'http://100pins-api.com/';
  public client_url = 'http://clientapi.100pins.com/';
  // public client_url = 'https://clientapi-dev.100pins.com/';
  public app_url = 'http://pages.100pins.com/html/';
  public image_root_url ='https://d5w9ceq0xr9bh.cloudfront.net/';
  public public_image_url = this.image_root_url + 'imgs/';
  public gallery_profile_picture_url = this.image_root_url +'gallery/userimages/profiles/';
  public rect_profile_picture_url = this.image_root_url +'gallery/userimages/smallimages/';
  public list_logo_url =this.image_root_url + 'imgs/lists/logos/';
  public default_nav_color = 'danger';
  public uploaded_image_url = 'https://d5w9ceq0xr9bh.cloudfront.net';
  public old_uploaded_image_url = 'https://s3.ap-south-1.amazonaws.com/100pinsdev';
  public link_preview_key = '5b54e80a65c77848ceaa4630331e8384950e09d392365';
  set_nav_color(color){
    localStorage.setItem('default_nav_color',color);
  }
  set_should_display_switch_list(toggle){
    localStorage.setItem('should_display_switch_list',toggle);
  }
  get_nav_color(){
    if(localStorage.getItem('default_nav_color'))
      return localStorage.getItem('default_nav_color');
    else
      return 'favourite';
  }
  get_should_display_switch_list(){
    if(localStorage.getItem('should_display_switch_list'))
      return localStorage.getItem('should_display_switch_list')=='true';
    else
      return true;
  }
}
