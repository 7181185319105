import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";
import {SingletonService} from "../../singleton";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-insert-merchants',
  templateUrl: './insert-merchants.component.html',
  styleUrls: ['./insert-merchants.component.css']
})
export class InsertMerchantsComponent implements OnInit {

  constructor(private api: ApiService, public auth: AuthService, public singleton: SingletonService, public route: ActivatedRoute) { }

  public merchants = [];
  public categories = [];
  public cities;
  public zip;
  public name;
  public country_name;
  public mini_name;
  public sms_name;
  public website;
  public email;
  public telephone;
  public area;
  public city;
  public latitude;
  public longitude;
  public fax;public url; public logo;
  public meta_title;
  public meta_description;
  public premoderation;
  public owner_id;
  public country_id;
  public category_id;
  public address;
  public category_blocks = [];
  public countries;
  public city_id;
  public success:boolean;
  public unlisted_merchants:any;
  public result;
  ngOnInit() {
    this.category_blocks = []
    this.api.get_countries().subscribe(res=>{
      this.countries = res
    })
    this.api.get_categories().subscribe(res=>{
      this.categories = res['categories']
      this.add_block(this.categories)
      }
    )
    this.route.queryParams.subscribe(res=>{
      if(res['name'] != '')
        this.name = res['name'];
      if(res['area'] !='')
        this.area = res['area'];
      if(res['city'] != '')
        this.city = res['city']
      if(res['country'] != '')
        this.country_name = res['country'];

    })
  }

  add_block(categories){
    let new_block = {selected_cat: '', values: categories}
    this.category_blocks.push(new_block)
  }
  get_cities(country_id) {

    this.api.get_cities_in_country(country_id).subscribe(res=>{
      this.cities=res;
      }

      );

  }
  select_cat(block, i){
    this.category_blocks.splice(i+1)
    console.log(block['selected_cat'])
    this.api.child_categories(block['selected_cat']).subscribe(res => {
      if(res['categories'].length)
        this.add_block(res['categories'])
    })
  }
 insert_merchant(){
    if(this.category_blocks[this.category_blocks.length-1]['selected_cat']){
     this.category_id  = this.category_blocks[this.category_blocks.length-1]['selected_cat']
    }else if(this.category_blocks[this.category_blocks.length-2]){
      this.category_id = this.category_blocks[this.category_blocks.length-2]['selected_cat']
    }
    let post_data={
      'name':this.name,'mini_name':this.mini_name,'sms_name':this.sms_name,'website':this.website,'address':this.address,'area':this.area,'zip':this.zip,'latitude':this.latitude,'longitude':this.longitude,'email':this.email,'phone':this.telephone,'fax':this.fax,'category':this.category_id,'url':this.url,'logo':this.logo,'meta_title':this.meta_title,'meta_description':this.meta_description,'premoderation':this.premoderation,'owner_id':this.owner_id,'city_id':this.city_id
    }
    this.api.admin_insert_merchant(post_data).subscribe(res=>{
      if(res['msg']=='success'){
        this.success = true;
        this.result = "Merchant has been added successfully"

      }else{
        this.success = false;
        this.result = "You are not an admin"
      }

    }, error => {
      this.success = false;
      this.result = "There is some problem while updating your data. Please try again later"
    })
  }

}
