import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() page:number = 1;
  @Input() prev_page:number;
  @Input() next_page:number;
  @Input() from:number;
  @Input() to:number;
  @Input() total:number;
  @Input() last_page: number;
  @Input() link: string;

  private params = {};
  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params))
    })

  }

  ngOnInit() {
    this.page = Number(this.page)
    this.last_page = Number(this.last_page)
    // if(this.total <= this.to)
    //   this.to = this.total
  }

  ngOnChanges(changes: SimpleChanges): void {
    if('page' in changes)
      this.page = changes['page']['currentValue']
    if('from' in changes)
      this.from = changes['from']['currentValue']
    if('to' in changes)
      this.to = changes['to']['currentValue']
    if('total' in changes)
      this.total = changes['total']['currentValue']
    if('last_page' in changes)
      this.last_page = changes['last_page']['currentValue']
    if(this.total <= this.to)
      this.to = this.total
  }

  navigate(page){
    this.params['page'] = page;
    this.router.navigate([this.link], {queryParams: this.params})
  }

}
