import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-private-message',
  templateUrl: './private-message.component.html',
  styleUrls: ['./private-message.component.css']
})
export class PrivateMessageComponent implements OnInit {

  @Input() member_id;
  @Input() list_id;


  public subject:string;
  public message:string;
  public send_as_list;
  public send_as;
  public send_sms_notification:boolean=false;
  public aliases = [];
  public send_private_msg:boolean=true;



  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getAliasNames(this.list_id).subscribe(res=>{
        this.aliases = res['aliases']
        this.send_as = this.aliases[0]
    })
  }

  send_message(){
    let post_data = {subject:this.subject, message:this.message, list_member_ids:[this.member_id], list_id: this.list_id, send_as:this.send_as, send_sms_notification:this.send_sms_notification}
    this.api.send_private_message(post_data).subscribe(res=>{
      this.subject = ''
      this.message = ''
    })
  }
 close() {
   this.send_private_msg=false;
  }

}
