import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {MemberBrowseComponent} from './list/member-browse/member-browse.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {MemberSearchComponent} from './list/member-search/member-search.component';
import {EditMemberInfoComponent} from './list/edit-member-info/edit-member-info.component';
import {MemberApprovalComponent} from './list/member-approval/member-approval.component';
import {SubgroupsComponent} from './list/subgroups/subgroups.component';
import {AttendanceDashboardComponent} from './list/attendance-dashboard/attendance-dashboard.component';
import {AttendanceCategoriesComponent} from './list/attendance-categories/attendance-categories.component';
import {ListSettingsComponent} from './list/list-settings/list-settings.component';
import {ListPermissionsComponent} from './list/list-permissions/list-permissions.component';
import {AttendanceSearchComponent} from './list/attendance-search/attendance-search.component';
import {ListFieldsComponent} from './list/list-fields/list-fields.component';
import {SeeMessagesComponent} from './list/see-messages/see-messages.component';
import {DeliveryReportsComponent} from './delivery-reports/delivery-reports.component';
import {InboxComponent} from './inbox/inbox.component';
import {SentMailComponent} from './sent-mail/sent-mail.component';
import {MessageComponent} from './message/message.component';
import {ListComponent} from './list/list.component';
import {UserComponent} from './user/user.component';
import {UrlComponent} from './url/url.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AuthGuardService} from './guards/auth-guard.service';
import {CategoryReviewsComponent} from './category-reviews/category-reviews.component';
import {RegisterComponent} from './register/register.component';
import {VerifyComponent} from './verify/verify.component';
import {PeoplePinningComponent} from './people-pinning/people-pinning.component';
import {BasicDataComponent} from './user/basic-data/basic-data.component';
import {UpdatePasswordComponent} from './user/update-password/update-password.component';
import {SecretQuestionComponent} from './user/secret-question/secret-question.component';
import {EmailNotificationsComponent} from './user/email-notifications/email-notifications.component';
import {UpdateMobileComponent} from './user/update-mobile/update-mobile.component';
import {UpdateEmailComponent} from './user/update-email/update-email.component';
import {OtherSettingsComponent} from './user/other-settings/other-settings.component';
import {PrefillComponent} from './list/prefill/prefill.component';
import {InviteComponent} from './invite/invite.component';
import {SendMessagesComponent} from './list/send-messages/send-messages.component';
import {CreateSubgroupComponent} from './list/create-subgroup/create-subgroup.component';
import {AddPermissionsComponent} from './list/add-permissions/add-permissions.component';
import {EditMyInfoComponent} from './list/edit-my-info/edit-my-info.component';
import {PersonalListComponent} from './list/personal-list/personal-list.component';
import {YellowPagesComponent} from './yellow-pages/yellow-pages.component';
import {BusinessAdminComponent} from './business-admin/business-admin.component';
import {ListBasicDataComponent} from './list/list-basic-data/list-basic-data.component';
import {MyListSettingsComponent} from './list/my-list-settings/my-list-settings.component';
import {JoinGroupComponent} from './list/join-group/join-group.component';
import {DownloadDataComponent} from './list/download-data/download-data.component';
import {BlankComponent} from './blank/blank.component';
import {SupportCausesComponent} from './support-causes/support-causes.component';
import {CauseDetailsComponent} from "./cause-details/cause-details.component";
import {UserLiteRegistrationsComponent} from "./list/user-lite-registrations/user-lite-registrations.component";
import {ContactUsComponent} from "./contact-us/contact-us.component";
import {DonationSettingsComponent} from "./list/donation-settings/donation-settings.component";
import {CustomMessageHelpComponent} from "./list/custom-message-help/custom-message-help.component";
import {ReportedMessagesComponent} from "./list/reported-messages/reported-messages.component";
import {PendingMessagesComponent} from "./list/pending-messages/pending-messages.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'a',
    component: BlankComponent,
  },{
    path: 'app',
    component: BlankComponent,
    // resolve: {
    //   url: 'externalUrlRedirectResolver'
    // },
    // data: {
    //   externalUrl: 'https://app.100pins.com'
    // }
  },{
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  }, {
    path: 'business-admin',
    loadChildren: () => import('./business-admin/business-admin.module').then(m => m.BusinessAdminModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
    // data: {title: 'Signup'}
  }, {
    path: 'signup/:list',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  }, {
    path: 'verify',
    loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  }, {
    path: 'people-pinning',
    loadChildren: () => import('./people-pinning/people-pinning.module').then(m => m.PeoplePinningModule),
    canActivate: [AuthGuardService]
  }, {
    path: 'yellow-pages',
    loadChildren: () => import('./yellow-pages/yellow-pages.module').then(m => m.YellowPagesModule)
  }, {
    path: 'join/:list',
    loadChildren: () => import('./list/join-group/join-group.module').then(m => m.JoinGroupModule)
  }, {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
  }, {
    path: 'client-api-uniquecode-check',
    loadChildren: () => import('./blank/blank.module').then(m => m.BlankModule)
  },

  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuardService],

  },{
    path: 'lists/:list_url/support-causes',
    loadChildren: () => import('./support-causes/support-causes.module').then(m => m.SupportCausesModule),
  },{
    path: 'lists/:list_url/payments/:id',
    loadChildren: () => import('./cause-details/cause-details.module').then(m => m.CauseDetailsModule),
  }, {
    path: 'lists/:list_url',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./list/list.module').then(m => m.ListModule)
  }, {
    path: ':url',
    data: {title: '100pins'},
    loadChildren: () => import('./url/url.module').then(m => m.UrlModule)
  },
  {
    path: ':category/:city',
    loadChildren: () => import('./category-reviews/category-reviews.module').then(m => m.CategoryReviewsModule)
  }, {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
