import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivateMessageRoutingModule } from './private-message-routing.module';
import {PrivateMessageComponent} from "./private-message.component";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [PrivateMessageComponent],
  imports: [
    CommonModule,
    PrivateMessageRoutingModule,
    FormsModule
  ],
  exports:[
    PrivateMessageComponent
  ]
})
export class PrivateMessageModule { }
