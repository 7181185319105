export class User {
  id: number;
  fname: string;
  lname: string;
  profession: string;
  work_place: string;
  image: string;
  picture: string;
  gender: string;
  city: string;
  email: string;
  mobile: string;
  dob: string;
  ID: string;
  list_member_id: number;
  username: string;
  selected = false;
  country_id: number;
  country_isd_code: string;
  mobile_verified_on: string;
  email_verified_on: string;
  mobile_authenticated:boolean;
  email_authenticated:boolean;
  country_name: string;
  pins: [];
  attendance = {
    'present' : 0,
    'absent' : 0,
    'present_dates' : [],
    'absent_dates' : [],
    'month_data' : [],
  };
  view_attendance = false;
  device_token: string;
  device_os: string;
  default_page: string;
  url: string;
  zip: string;
  address: string;
}
