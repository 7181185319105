import { Component } from '@angular/core';
import { SingletonService } from './singleton';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Location } from '@angular/common';
import {Title} from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public list_url: string;
  public  is_admin_page: boolean = false;

  constructor(private api: ApiService, public auth:AuthService, public singleton: SingletonService, private route:ActivatedRoute, private location: Location, private titleService: Title, private router: Router) {
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {

        if(this.router.url.includes('/admin/') || this.router.url == '/admin')
          this.is_admin_page = true;
        else
          this.is_admin_page = false;

        var title = this.getTitle(router.routerState, router.routerState.root).join('-');
        titleService.setTitle(title);
      }
    });

    /* this is added for the Google Analytics added on May 7th by arun*/
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
      console.log('its for google analytics')
      /** END */
    })
    //Google Analytics end



    let device_manufacturer = localStorage.getItem('device_manufacturer')
    let device_model = localStorage.getItem('device_model')
    let device_os = localStorage.getItem('device_os')
    let device_os_version = localStorage.getItem('device_os_version')
    let medium_of_access = device_manufacturer ? 'mobile' : 'web'
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        let location_url = window.location.origin + ev.url
        let post_data = {
          list_members_id: JSON.parse(localStorage.getItem('list_member'))?.id ?? null,
          user_id: JSON.parse(localStorage.getItem('user'))?.id ?? this.auth.user?.id ?? null,
          list_id: JSON.parse(localStorage.getItem('list'))?.id ?? this.auth.get_list()?.id ?? null,
          medium_of_access: medium_of_access,
          device_manufacturer: device_manufacturer,
          device_model: device_model,
          device_os: device_os,
          device_os_version: device_os_version,
          page: location_url,
        }
        console.log(post_data)
        this.api.user_actions_logging(post_data).subscribe()
      }
    });


  }
  title = 'pins-ng';

  public loading:boolean = true;
  ngOnInit() {
    let url  = this.location.path();
    let params = url.split('/');
    this.loading = true;
    if(params[1] == 'lists'){
      this.loading = false;
      this.list_url = params[2];
      // this.api.get_list_data(this.list_url).subscribe(res=>{
      //   this.list_data = res;
      // })
    }
  }

  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
