import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-insert-city',
  templateUrl: './insert-city.component.html',
  styleUrls: ['./insert-city.component.css']
})
export class InsertCityComponent implements OnInit {

  constructor(private api:ApiService, public auth: AuthService) { }
  public country_id;
  public state_name;
  public city_name;
  public district_name;
  public url;
  public latitude;
  public longitude;
  public message: string = '';
  public is_error: boolean=false;



  ngOnInit() {
  }
  insert_city(){
    console.log(this.city_name)
    console.log(this.state_name)
    if(!this.city_name && !this.state_name && !this.district_name && !this.url && !this.latitude && !this.longitude){

        this.message = "Please enter all the fields";
      this.is_error = true;
      return;

    }
    let post_data={
      city:this.city_name,
      state:this.state_name,
      district:this.district_name,
      url:this.url,
      latitude:this.latitude,
      longitude:this.longitude,
      country_id: this.country_id

    }
    this.api.admin_insert_city(post_data).subscribe(res=>{
      if(res['success'] == 'Y'){
        this.message = "City inserted";
        this.is_error = false;
      }
      else if(res['success'] == 'Y'){
        this.message = "URL already exists";
        this.is_error = true;

      }else{
        this.message = "You are not an admin";
        this.is_error = true;
      }
      console.log(this.message)
    }, error => {
      this.message = "There is some error while uploading data. Please try again later or contact 100pins support.";
      this.is_error = true;

    })

  }

}
